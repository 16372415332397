.host {
  padding: 33px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .identity {
    margin-bottom: 53px;
  }

  form {
    width: 100%;

    p {
      font-size: 1.4rem;
      margin-bottom: 7px;
    }

    span {
      font-weight: 500;
      font-size: 2rem;
      color: var(--textHeadingColor);
    }

    input {
      margin-top: 25px;
      margin-bottom: 20px;
    }
  }

  .timeToResend {
    span {
      font-weight: 500;
    }
  }

  .resendCode {
    background-color: transparent;
    border: none;
    color: var(--accentColor);
    text-decoration: underline;
    font-size: 1.2rem;
    font-weight: 400;
  }

  .resendMessage {
    margin-top: 14px;

    p {
      font-size: 1.2rem;
      line-height: 1.6rem;
      font-weight: 400;
    }

    span {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
}
