.host {
  padding: 30px 0;

  header {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 12px 1fr 12px;
    width: 100%;
    text-align: center;

    h1 {
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3rem;
      text-transform: uppercase;
    }
  }

  form {
    margin-top: 50px;
    padding: 0;

    label {
      span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }

    input {
      text-align: left;
      background-color: transparent;
      border-color: transparent;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 0;
      font-family: inherit;
      color: inherit;
      height: auto;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3rem;
      margin-top: 5px;
      padding-bottom: 4px;
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 10px;
    }
  }
}

.submitBtn {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  height: 50px;
  padding: 0;
  background-color: #ff8a00;
}

.resendCodeBtn {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  height: 50px;
  padding: 0;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  margin-top: 60px;
}

.message,
.timer {
  color: #888888;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  white-space: break-spaces;

  span {
    color: #fff;
  }
}

.timer {
  color: #fff;

  span {
    color: #888888;
  }
}
