.host {
  padding: 33px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .identity {
    margin-bottom: 53px;
  }

  form {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 500;
      color: var(--textHeadingColor);
      margin-bottom: 15px;
    }

    p {
      font-size: 1.4rem;
      line-height: 1.8rem;
      margin-bottom: 15px;
    }

    input {
      margin-bottom: 20px;
    }
  }

  .policyMessage {
    font-weight: 500;
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 1.8rem;

    a {
      text-decoration: underline;
    }
  }
}

.docs {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 20px;
    white-space: break-spaces;
    padding-top: 17px;

    span {
        cursor: pointer;
    }
}

.docsPopup {
    padding: 5px 20px 20px;
    .docRow {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 18px 0;
        border-bottom: 2px solid #181818;
        color: #181818 !important;
        outline: none;

        svg {
            margin-right: 20px;
        }
    }

    p {
        margin-top: 50px;
        color: rgb(24, 24, 24, 0.6);
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        white-space: break-spaces;
    }
}
