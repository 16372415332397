.host {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #181818;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
}

.hostOut {
  animation: 0.4s hostOut forwards;
  animation-delay: 0.4s;

  @keyframes hostOut {
    0% {
      opacity: 1;
      z-index: 3;
    }

    100% {
      opacity: 0;
      z-index: -1;
    }
  }
}

.logo {
  opacity: 0;
  transform: translateY(20px);
  animation: 0.8s logoIn forwards;

  @keyframes logoIn {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.logoOut {
  opacity: 1;
  transform: translateY(0);
  animation: 0.4s logoOut forwards;

  @keyframes logoOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }

    100% {
      opacity: 0;
      transform: translateY(-20px);
    }
  }
}
