.host {
  width: 100%;

  h2 {
    margin-top: 7px;
    font-size: 1.4em;
    font-weight: 300;
  }

  img {
    width: 220px;
  }
}
