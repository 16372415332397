.host {
    height: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    header {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 12px 1fr 12px;
        width: 100%;
        text-align: center;

        h1 {
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 3rem;
            text-transform: uppercase;
        }
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        font-weight: 400;
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 20px;
        text-align: center;
        text-transform: uppercase;
        white-space: break-spaces;
    }

    p {
        font-weight: 400;
        font-size: 2rem;
        line-height: 2rem;
        text-align: center;
        text-transform: uppercase;
        max-width: 280px;
    }

    img {
        width: 150px;
        height: 48px;
        margin-top: 40px;
    }

    svg {
        margin-bottom: 30px;
    }
}

.retryBtn {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    height: 50px;
    padding: 0;
    background-color: #ff8a00;
}

.android {
    &List {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 340px;
        max-width: 100%;

        img {
            margin: 0 auto;
        }
    }

    &Title {
        margin-bottom: 0;
    }

    &Step {
        position: relative;
        padding: 14px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 35px;
        font-size: 1.4rem;
        line-height: 1.4;
        font-weight: 400;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        &:before, &:after {
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            border-radius: 4px;
            background-color: #ffffff;
            content: '';
        }

        &:before {
            top: 0;
        }

        &:after {
            bottom: 0;
        }
    }

    &Count {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #ffffff;
        color: #181818;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 2rem;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &Button {
        margin: 0 auto;
        position: relative;
    }

    &Label {
        position: absolute;
        top: 50%;
        left: 54px;
        transform: translateY(-50%);
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.33;
    }
}
