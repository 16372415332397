.host {
    padding: 30px 0;

    header {
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-columns: 12px 1fr 12px;
        width: 100%;
        text-align: center;

        h1 {
            font-weight: 400;
            font-size: 2.4rem;
            line-height: 3rem;
            text-transform: uppercase;
        }
    }

    form {
        margin-top: 110px;
        padding: 0;

        label {
            span {
                font-weight: 400;
                font-size: 1.2rem;
                line-height: 2rem;
            }
        }

        input {
            text-align: left;
            background-color: transparent;
            border-color: transparent;
            border-bottom: 2px solid rgba(255, 255, 255, 0.4);
            border-radius: 0;
            font-family: inherit;
            color: inherit;
            height: auto;
            font-weight: 500;
            font-size: 2.4rem;
            line-height: 3rem;
            margin-top: 5px;
            padding-bottom: 4px;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.countryPicker {
    padding: 17px 0 0;
    display: flex;
    align-items: center;

    svg {
        margin: 0 12px 0 7px;
    }

    span {
        font-size: 1.2rem;
        font-weight: 400;
    }

    img {
        height: 15px;
        width: auto;
    }
}

.submitBtn {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    height: 50px;
    padding: 0;
    background-color: #ff8a00;

    &:disabled {
        background-color: rgba(136, 136, 136, 0.2) !important;
        border-color: rgba(136, 136, 136, 0) !important;
        color: #888888;
        opacity: 1 !important;
    }
}

.docs {
    color: #888888;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    margin-bottom: 20px;
    white-space: break-spaces;
    padding-top: 17px;

    span {
        cursor: pointer;
        color: #fff;
    }
}

.docsPopup {
    .docRow {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 400;
        padding: 18px 0;
        border-bottom: 2px solid #181818;
        color: #181818 !important;
        outline: none;

        svg {
            margin-right: 20px;
        }
    }

    p {
        margin-top: 50px;
        color: rgb(24, 24, 24, 0.6);
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
        white-space: break-spaces;
    }
}

.docsPopup {
    font-family: 'Stratos', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 40px 25px 30px;
    color: #181818;
}
