.host {
    padding: 10vh 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
}

.langSelector {
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
}

.langPicker {
    padding-bottom: 8px;
    border-bottom: 1px solid currentColor;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    font-size: 2em;
    text-transform: uppercase;
}

.countryPicker {
    padding: 17px 0 0;
    display: flex;
    align-items: center;

    svg {
        margin: 0 12px 0 7px;
    }

    span {
        font-size: 1.2rem;
        font-weight: 400;
    }

    img {
        height: 15px;
        width: auto;
    }
}

.submitBtn {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;
    height: 50px;
    padding: 0;
    background-color: #ff8a00;
    border-radius: 100px;

    &:disabled {
        background-color: rgba(136, 136, 136, 0.2) !important;
        border-color: rgba(136, 136, 136, 0) !important;
        color: #888888;
        opacity: 1 !important;
    }
}
