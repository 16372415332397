.host {
  width: 100%;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  position: relative;
  min-width: fit-content;
  user-select: none;
  border: 1px solid var(--accentColor);
  border-radius: var(--button-radius);
  transform: translateZ(0);
  min-height: 40px;
  padding: 14px 28px;
  background: var(--accentColor);
  color: var(--buttonTextColor);

  &:disabled {
    opacity: 0.3;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover {
    background-color: var(--buttonHoverColor);
    border-color: var(--buttonHoverColor);
  }
}

.withRipple {
  overflow: hidden;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 0.4s linear;
  background: var(--buttonRippleColor);
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2.5);
  }
}
