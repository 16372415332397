.host {
    font-family: 'Stratos', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 40px 25px 30px;
    color: #181818;
    text-align: center;
    text-transform: uppercase;

    h3 {
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 2rem;
        margin-bottom: 17px;
    }

    h2 {
        font-weight: 400;
        font-size: 3rem;
        line-height: 3rem;
        margin-bottom: 25px;
    }

    button {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 2rem;
        line-height: 3rem;
        height: 50px;
        padding: 0;
        background-color: #ff8a00 !important;
        color: #fff;
        margin-top: 50px;
        border: none !important;
        outline: none !important;
    }
}
