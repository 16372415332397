.spinner {
  margin-bottom: 64px;
  animation-delay: 1s;
  animation: 2.5s spinner infinite;

  @keyframes spinner {
    0% {
      transform: rotate(0);
    }

    1% {
      transform: rotate(0);
    }

    15% {
      transform: rotate(0);
    }

    25% {
      transform: rotate(90deg);
    }

    26% {
      transform: rotate(90deg);
    }

    39% {
      transform: rotate(90deg);
    }

    50% {
      transform: rotate(180deg);
    }

    51% {
      transform: rotate(180deg);
    }

    64% {
      transform: rotate(180deg);
    }

    75% {
      transform: rotate(270deg);
    }

    76% {
      transform: rotate(270deg);
    }

    89% {
      transform: rotate(270deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  circle {
    r: 0px;
    animation: 0.4s spinnerCircleIn forwards;
    animation-delay: 0.4s;

    @keyframes spinnerCircleIn {
      0% {
        r: 0px;
      }

      100% {
        r: 10px;
      }
    }
  }

  .spinnerCircleOut {
    animation: 0.4s spinnerCircleOut forwards;
    r: 10px;

    @keyframes spinnerCircleOut {
      0% {
        r: 10px;
      }

      100% {
        r: 0px;
      }
    }
  }
}
