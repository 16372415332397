.Toastify__toast {
  padding: 30px 30px 10px !important;
  position: relative;
}

.Toastify__toast-body {
  text-align: center;
  font-size: 1.6rem;

  & > div {
    white-space: pre;
  }
}

.Toastify__close-button {
  height: 30px;
  opacity: 1 !important;
  position: absolute;
  right: 20px;
  top: calc(50% + 10px);
  transform: translateY(-50%);
}

.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast--error {
  background: #cc1b1b !important;
  color: #fff !important;
}

.Toastify__close-button > svg {
  height: 20px !important;
  width: 20px !important;

  path {
    fill: #fff !important;
  }
}
