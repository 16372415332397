.host {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px;

  h1 {
    font-size: 5rem;
    font-weight: 500;
  }

  p {
    text-align: center;
    margin-top: 30px;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.3;
  }
}
