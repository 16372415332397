/* Fade In/Out */

.fadeIn {
  opacity: 1;
  animation: fadeIn 0.6s ease-in-out;
}

.fadeOut {
  opacity: 0;
  animation: fadeOut 0.6s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
