@import './variables.scss';
@import './animations.scss';
@import './notifications.scss';
@import '../fonts/index.scss';

* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

:root {
  scroll-behavior: auto !important;
}

body {
  background-color: var(--backgroundColor);
  height: calc(var(--vh, 1vh) * 100);
}

html,
body {
  font-family: var(--font-family);
  font-size: 10px;
  color: var(--textColor);
  overflow-x: hidden;
  overflow-y: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-weight: 400;
  font-size: 1rem;
}

a {
  color: var(--accentColor);
  text-decoration: none;
  transition: var(--base-transition);

  &:hover {
    color: var(--buttonHoverColor);
  }
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: var(--buttonTextColor);
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  transition: var(--base-transition);

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

input {
  appearance: initial;
  background-color: var(--inputBgColor);
  border: 1px solid var(--dividerColor);
  border-radius: 10px;
  width: 100%;
  width: -webkit-fill-available;
  height: 44px;
  font-size: 1.6rem;
  color: var(--textColor);
  font-weight: 500;
  transition: var(--base-transition);
  text-align: center;

  &:focus {
    outline: none;
    border-color: var(--accentColor);
  }
}

#root {
  height: 100%;
}

[data-rsbs-state='closing'] [data-rsbs-overlay]:before {
  opacity: 0;
}

[data-rsbs-overlay]:before {
  content: '';
  transition: var(--base-transition);
  position: absolute;
  display: none;
  width: 60px;
  height: 4px;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
  background-color: #fff;
}

.showStick [data-rsbs-overlay]:before {
  display: block;
}