.host {
  padding: 30px 0;

  header {
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: 12px 1fr 12px;
    width: 100%;
    text-align: center;

    h1 {
      font-weight: 400;
      font-size: 2.4rem;
      line-height: 3rem;
      text-transform: uppercase;
    }
  }

  form {
    margin-top: 50px;
    padding: 0;

    label {
      span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }

    input {
      text-align: left;
      background-color: transparent;
      border-color: transparent;
      border-bottom: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 0;
      font-family: inherit;
      color: inherit;
      height: auto;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 3rem;
      padding-bottom: 4px;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.submitBtn {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  height: 50px;
  padding: 0;
  background-color: #ff8a00;
  margin-top: 50px;

  &:disabled {
    background-color: rgba(136, 136, 136, 0.2) !important;
    border-color: rgba(136, 136, 136, 0) !important;
    color: #888888;
    opacity: 1 !important;
  }
}

.resendCodeBtn {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  height: 50px;
  padding: 0;
  background-color: transparent !important;
  border: 2px solid #fff !important;
  margin-top: 60px;
}

.message,
.timer {
  color: #888888;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;

  span {
    color: #fff;
  }
}

.timer {
  color: #fff;

  span {
    color: #888888;
  }
}

.field,
.dateFieldSet,
.genderFieldSet {
  position: relative;
  margin-bottom: 27px;

  label {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
  }

  input {
    text-transform: uppercase;
    margin-top: 5px;
    transition: var(--base-transition);
  }
}

.requiredStar {
  position: absolute;
  margin-left: 4px;
  top: 0;
}

.dateFieldSet {
  & > div {
    display: flex;
    align-items: center;
  }

  [name='day'],
  [name='month'] {
    width: 50px;
  }

  [name='year'] {
    width: 90px;
  }

  [name='month'] {
    margin-left: 25px;
    margin-right: 50px;
  }

  input::placeholder {
    color: #303030;
  }
}

.genderFieldSet {
  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 10px;
    margin-top: 15px;
  }
}

.genderField {
  border: 2px solid rgba(255, 255, 255, 0.2);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  transition: var(--base-transition);
}

.genderFieldActive {
  border-color: #fff;
  background: #fff;
  color: #181818;
}

.fieldError {
  position: absolute;
  top: 0;
  right: 0;
  color: #f05633;
  font-size: 1.2rem;
}

.fieldInvalid {
  border-bottom-color: rgb(240, 86, 51, 0.4) !important;
}
