.host {
  height: 100%;

  header {
    background-color: var(--headerBgColor);
    height: 48px;

    .headerContent {
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: 48px 1fr 48px;
      justify-items: center;
      height: 100%;
    }

    .arrow {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h1 {
      font-size: 2rem;
      font-weight: 500;
      white-space: pre;
    }
  }

  main {
    height: calc(100% - 48px);
    padding: 0 16px;
  }

  main,
  .headerContent {
    max-width: var(--main-container-size);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
