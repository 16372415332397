:root {
  /* Layout */
  --main-container-size: 600px;

  /* Button */
  --button-radius: 50px;

  /* Animation */
  --base-transition-timings: 0.2s ease-in-out;
  --base-transition: all var(--base-transition-timings);

  /* Fonts */
  --font-family: 'Gotham Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
