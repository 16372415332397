.host {
  padding: 33px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .identity {
    margin-bottom: 53px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 3rem;
      margin-bottom: 24px;
      color: var(--textHeadingColor);
      font-weight: 500;
    }

    p {
      font-size: 2rem;
      line-height: 1.8rem;

      span {
        color: var(--accentColor);
        font-weight: 500;
      }
    }

    .help {
      font-size: 1.6rem;
      line-height: 1.8rem;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
