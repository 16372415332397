.countryPickerPopup {
    .countryPickerRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 2px solid #181818;
        }

        img {
            margin-right: 15px;
            width: 40px;
            height: 40px;
            object-fit: cover;
        }

        & > div {
            display: flex;
            align-items: center;
        }

        span {
            font-weight: 500;
            font-size: 2rem;
            line-height: 2rem;
        }
    }
}

.countryPickerPopup {
    font-family: 'Stratos', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    padding: 40px 25px 30px;
    color: #181818;
}
