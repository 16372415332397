.host {
  font-family: 'Stratos', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #fff;
  height: 100%;
  background-color: #181818;

  main {
    padding: 0 25px;
    max-width: var(--main-container-size);
    margin: 0 auto;
    height: 100%;
  }
}

:global body {
    background-color: #181818;
}
