.host {
  padding: 33px 0;
  width: 100%;
  
  form {
    margin-top: 50px;
    padding: 0;
    width: 100%;

    label {
      span {
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 2rem;
      }
    }

    input {
      text-align: left;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.submitBtn {
  height: 50px;
  padding: 0;
  margin-top: 30px;
}

.field,
.dateFieldSet,
.genderFieldSet {
  position: relative;
  margin-bottom: 27px;

  label {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  input {
    margin-top: 5px;
    transition: var(--base-transition);
  }
}

.requiredStar {
  position: absolute;
  margin-left: 4px;
  top: 0;
}

.dateFieldSet {
  & > div {
    display: flex;
    align-items: center;
  }

  [name='day'],
  [name='month'] {
    width: 50px;
  }

  [name='year'] {
    width: 90px;
  }

  [name='month'] {
    margin-left: 25px;
    margin-right: 50px;
  }
}

.genderFieldSet {
  & > div {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 10px;
    margin-top: 15px;
  }
}

.genderField {
  border: 1px solid var(--dividerColor);
  background-color: var(--inputBgColor);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.4rem;
  transition: var(--base-transition);
}

.genderFieldActive {
  border-color: var(--accentColor);
}

.fieldError {
  position: absolute;
  top: 0;
  right: 0;
  color: #f05633;
  font-size: 1.2rem;
}

.fieldInvalid {
  border-bottom-color: rgb(240, 86, 51, 0.4) !important;
}
