.host {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 0 8px;
  text-align: center;

  h2 {
    font-size: 2.4rem;
    line-height: 3rem;
    font-weight: 500;
    color: var(--textHeadingColor);
    margin: 24px 0;
  }

  p {
    font-size: 1.6rem;
    line-height: 1.8rem;
    white-space: pre-wrap;
  }

  button {
    margin-top: 37px;
  }
}
